<!--顶部视频-->
<template>
  <div class="topVideoDiv">
    <div class="topDiv">
      <!--图片部分-->
      <!--<div class="banner-content" v-if="videoControl == false">
        <div class="centerTitle">
          <span>以地方特色，创地标品牌，助力乡村振兴，共享互通，联合共生</span>
        </div>
        <div class="subCenterTitle">
          <span>山东地理标志产业协会·融入数字科技互联地标产业生态</span>
        </div>
        <div class="video">
          <button class="videoBtn" @click="videoClick()">观看视频 <img src="@/assets/img/video.png" alt=""></button>
        </div>
      </div>-->
      <!--视频部分-->
      <div class="top-video" v-if="videoControl">
        <div class="video-content">
          <!--一进页面首先展示封面图，接着视频循环播放，点击按钮弹出弹窗播放视频-->
          <video ref="myVideo" src="../../video/topVideo5.mp4" autoplay muted loop style="width: 100%" id="my-video" poster="../../assets/img/banner.jpg">
            <source src="../../video/topVideo5.mp4" type="video/mp4">
            Your browser does not support HTML5 video.
          </video>
          <!--此方法黑屏时间较长-->
          <!--<video-player
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions"
            @ended="onPlayerEnded()">
          </video-player>-->
        </div>
        <div class="titleDiv">
          <div class="centerTitle">
            <span>以地方特色 · 创地标品牌 · 助力乡村振兴</span>
          </div>
          <div class="subCenterTitle">
            <span>融入数字科技 · 互联地标产业 · 共享互通共生</span>
          </div>
          <div class="video">
            <button class="videoBtn" @click="videoClick()">观看视频 <img src="@/assets/img/video.png" alt=""></button>
          </div>
        </div>
      </div>
    </div>


    <div class="dialogDiv">
      <el-dialog
        v-if="viewVideo"
        :visible.sync="viewVideo"
        top="0">
        <div class="videoDiv">
          <video
            :src="this.videoUrl"
            width="50%"
            autoplay="autoplay"
            controls="controls"
            :state="viewVideo"
            ref="vueRef"
          ></video>
        </div>
      </el-dialog>
    </div>


  </div>
</template>

<script>
import 'video.js/dist/video-js.css'
import {videoPlayer} from 'vue-video-player'
export default {
  name: "topVideo",
  data() {
      return {
        // 视频播放
        playerOptions: {
          playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
          autoplay: true, //如果true,浏览器准备好时开始回放。
          controls: false, //不显示暂停、声音、进度条组件
          muted: true, // 默认情况下将会消除任何音频。
          loop: true, // 视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          // aspectRatio : '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [{
            type: "video/mp4",
            src: require('../../video/videoTop.mp4')
          }],
          poster: require('../../assets/img/banner.png'), //你的封面地址
          notSupportedMessage: '正在加载...', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar : {
            timeDivider : true,//当前时间和持续时间的分隔符
            durationDisplay : true,//显示持续时间
            remainingTimeDisplay : false,//是否显示剩余时间功能
            fullscreenToggle : true  //全屏按钮
          },
          // techOrder: ['flash', 'html5']
        },
        videoControl: true,
        // 弹窗展示状态 默认false
        viewVideo: false,
        // 视频链接
        videoUrl: require('../../video/topVideo5.mp4'), //require('../../video/videoTop.mp4'),
      }
  },
  components: {
    videoPlayer
  },
  methods: {
    //观看视频按钮点击
    videoClick() {
      //this.videoControl = true
      this.viewVideo = true
      this.$refs.vueRef.play();//播放
    },

    // 视频播完回调
    onPlayerEnded() {
      this.videoControl = false
    },
  }
  }
</script>

<style scoped lang="less">
  .topVideoDiv {
    text-align: center;
  }
  .topDiv {
    .top-video {
      overflow: hidden;

      .video-content {
        position: relative;
        z-index: 10;
      }
      .titleDiv {
        width: 100%;
        position: absolute;
        top: calc(100% / 4);
        left: 0;
        z-index: 11;
        display: flex;
        flex-wrap: wrap;

        .centerTitle {
          width: 100%;
          height: 55px;
          font-size: 70px;
          font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 55px;

        }

        .subCenterTitle {
          width: 100%;
          margin-top: 35px;
          height: 24px;
          font-size: 50px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 36px;
          letter-spacing: 2px;

        }

        .video {
          width: 100%;
          margin-top: 70px;

          .videoBtn {
            border: none;
            width: 110px;
            height: 38px;
            background: #186CF5;
            border-radius: 5px;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 21px;
            img {
              vertical-align: middle;
            }
          }
          .videoBtn:hover {
            cursor: pointer;
          }
        }

      }
    }
  }

  .dialogDiv {
    .videoDiv {
      margin-top: 10%;
    }
  }
  ::v-deep .el-dialog {
    background-color: transparent;
    max-width: 100vw;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
  }
  ::v-deep .el-dialog__headerbtn {
    font-size: 60px;
  }
  ::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: #ffffff;
  }

  @media screen and (max-width: 1366px) {
    .topDiv {
      .top-video {
        overflow: hidden;

        .video-content {
          position: relative;
          z-index: 10;
        }
        .titleDiv {
          width: 100%;
          position: absolute;
          top: calc(100% / 5);
          left: 0;
          z-index: 11;
          display: flex;
          flex-wrap: wrap;

          .centerTitle {
            width: 100%;
            height: 55px;
            font-size: 70px;
            font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 55px;

          }

          .subCenterTitle {
            width: 100%;
            margin-top: 35px;
            height: 24px;
            font-size: 50px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 36px;
            letter-spacing: 2px;

          }

          .video {
            width: 100%;
            margin-top: 70px;

            .videoBtn {
              border: none;
              width: 110px;
              height: 38px;
              background: #186CF5;
              border-radius: 5px;
              font-size: 14px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 21px;
            }
            .videoBtn:hover {
              cursor: pointer;
            }
          }

        }
      }
    }

    .dialogDiv {
      .videoDiv {
        margin-top: 10%;
      }
    }
    ::v-deep .el-dialog {
      background-color: transparent;
      max-width: 100vw;
      width: 100%;
      height: 100vh;
      margin: 0;
      padding: 0;
    }
    ::v-deep .el-dialog__headerbtn {
      font-size: 60px;
    }
    ::v-deep .el-dialog__headerbtn .el-dialog__close {
      color: #ffffff;
    }
  }

</style>
