<!--地标产业联盟-->
<template>
  <div class="unionContentDiv">
    <div class="union-div">
      <div class="union-title">
        <span>地标产业联盟</span>
      </div>
      <!--<div class="carouselDiv" @click="unionClick">
        <vue-seamless-scroll :data="unionListOne"
                             :class-option="optionRight"
                             class="warp">
          <ul class="ul-item">
            <li class="li-item" v-for="(list, index) in unionListOne" :key="index">
              <img class="top-img" :src="list.url" alt="" />
            </li>
          </ul>
        </vue-seamless-scroll>
        <vue-seamless-scroll :data="unionListTwo"
                             :class-option="optionLeft"
                             class="warp">
          <ul class="ul-item">
            <li class="li-item" v-for="(list, index) in unionListTwo" :key="index">
              <img class="top-img" :src="list.url" alt="" />
            </li>
          </ul>
        </vue-seamless-scroll>
        <vue-seamless-scroll :data="unionListThree"
                             :class-option="optionRight"
                             class="warp">
          <ul class="ul-item">
            <li class="li-item" v-for="(list, index) in unionListThree" :key="index">
              <img class="top-img" :src="list.url" alt="" />
            </li>
          </ul>
        </vue-seamless-scroll>
        <vue-seamless-scroll :data="unionListFour"
                             :class-option="optionLeft"
                             class="warp">
          <ul class="ul-item">
            <li class="li-item" v-for="(list, index) in unionListFour" :key="index">
              <img class="top-img" :src="list.url" alt="" />
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>-->
      <div class="unionClass">
        <div class="union-content">
          <div v-for="(item, index) in unionList" :key="index" class="union-card"  @click="unionClick">
            <div>
              <img :src="item.url" alt/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
  name: "union",
  components: { vueSeamlessScroll },
  data() {
    return {
      //地标产业联盟
      unionList: [],
      unionListOne: [],
      unionListTwo: [],
      unionListThree: [],
      unionListFour: [],
      ulStyle: {},
    }
  },
  computed: {
    optionRight () {
      return {
        direction: 3, //0向下 1向上 2向左 3向右
        limitMoveNum: 1, //开始无缝滚动的数据量
        step: 1, //数值越大速度滚动越快
        hoverStop: false, //是否开启鼠标悬停
      }
    },
    optionLeft () {
      return {
        direction: 2,
        limitMoveNum: 1,
        step: 1,
        hoverStop: false,
      }
    }
  },
  mounted() {
    this.getLink()
  },
  methods: {
    //调用地标产业联盟接口
    getLink() {
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/industrial/queryUnionHome",
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.unionList = res.data.data ? res.data.data : []
          for (let i = 0; i < this.unionList.length; i++) {
            this.unionList[i].url = this.unionList[i].allianceLogo
          }
          /*let length = this.unionList.length
          let sliceNum = length % 4 === 0 ? length / 4 : Math.floor((length / 4) + 1)
          let newArr = []
          for (let i = 0; i < length; i+=sliceNum) {
            let arr = this.unionList.slice(i, i+sliceNum)
            newArr.push(arr)
          }
          console.log("newArr", newArr)
          this.unionListOne = newArr[0]
          this.unionListTwo = newArr[1]
          this.unionListThree = newArr[2]
          this.unionListFour = newArr[3]*/
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    unionClick() {
      this.$router.push({
        path: '/alliance/allianceDetail'
      });
    }
  }
}
</script>

<style scoped lang="less">
  .unionContentDiv {
    text-align: center;
  }
  .union-div {
    background-color: #FFFFFF;
    overflow: hidden; //解决子div设置margin-top使父div也跟着向下移动的问题
    margin-bottom: 20px;
    .union-title {
      font-size: 30px;
      font-weight: bold;
      margin-top: 40px;
      margin-bottom: 30px;
      letter-spacing: 3px;
    }
    .unionClass {
      width: 75%;
      margin: auto;
      .union-content {
        flex-wrap: wrap;
        justify-content: center;
        align-content: space-between;
        display: grid;
        grid-template-columns: auto auto auto auto auto auto auto auto; //8列
        .union-card {
          transition: all .2s ease;
          width: 130px;
          height: 120px;
          background: rgba(255, 255, 255, 0.92);
          border-radius: 5px;
          margin: 10px 10px;
          box-sizing: border-box;
          text-align: left;
          img {
            width: 120px;
            height: 90px;
          }
        }
        .union-card:hover {
          cursor: pointer;
        }
      }
    }
  }
</style>
