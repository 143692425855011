<!--地标书画展-->
<template>
  <div class="paintingDiv">
    <div class="draw-div">
      <div class="draw-title">
        <span>地标书画展</span>
      </div>
      <div class="draw-content" @click="drawClick()">
        <el-carousel :interval="4000" type="card" height="410px">
          <el-carousel-item v-for="(item,index) in drawImgs" :key="index">
            <img :src="item.contentImg" alt="" style="width: 100%; height: 100%;">
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "painting",
  data() {
      return {
        //地标书画展
        drawImgs: [],
      }
  },
  methods: {
    //地标书画展查询
    getPainting() {
      let param = {
        id: "744967562642857984", //传值写死
      }
      axios({
        method: "post",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/api/portal/graphicdisplay/queryGraphic',
        data: param
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
           this.drawImgs = res.data.data
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    //地标书画展点击跳转
    drawClick() {
      this.$router.push({
        path: '/painting/paintingDetail'
      });
    },
  },
  mounted() {
      this.getPainting()
  }
}
</script>

<style scoped lang="less">
  .paintingDiv {
    text-align: center;
  }
  .draw-div {
    height: 630px;
    background-color: #F4F9FF;
    background-image: url(../../assets/img/rightBelow.png);
    background-repeat:no-repeat;
    background-position: right bottom;
    overflow: hidden;
  .draw-title {
    font-size: 30px;
    font-weight: bold;
    margin-top: 65px;
    letter-spacing: 3px;
  }
  .draw-content {
    display: flex;
    justify-content: center;
    margin-top: 56px;
    margin-bottom: 56px;
  }
  }
  ::v-deep.el-carousel--horizontal {
    width: 76rem;
  }
  //轮播图指示器设置为圆点
  /deep/ .el-carousel__indicator--horizontal .el-carousel__button {
    width: 10px;
    height: 10px;
    background: #dee9f6;
    border: 1px solid #dee9f6;
    border-radius: 50%;
    opacity: 0.5;
  }
  /deep/ .el-carousel__indicator--horizontal.is-active .el-carousel__button{
    width: 10px;
    height: 10px;
    background: #126ff7;
    border-radius: 50%;
    opacity: 1;
  }
  @media screen and (max-width: 1366px) {
    .paintingDiv {
      text-align: center;
    }
    .draw-div {
      height: 630px;
      background-color: #F4F9FF;
      background-image: url(../../assets/img/rightBelow.png);
      background-repeat:no-repeat;
      background-position: right bottom;
      overflow: hidden;
      .draw-title {
        font-size: 30px;
        font-weight: bold;
        margin-top: 65px;
        letter-spacing: 3px;
      }
      .draw-content {
        margin: 56px 100px;
      }
    }
    //轮播图指示器设置为圆点
    /deep/ .el-carousel__indicator--horizontal .el-carousel__button {
      width: 10px;
      height: 10px;
      background: #dee9f6;
      border: 1px solid #dee9f6;
      border-radius: 50%;
      opacity: 0.5;
    }
    /deep/ .el-carousel__indicator--horizontal.is-active .el-carousel__button{
      width: 10px;
      height: 10px;
      background: #126ff7;
      border-radius: 50%;
      opacity: 1;
    }
  }

</style>
