<!--行业智库-->
<template>
  <div class="wisdomContentDiv">
    <div class="wisdom-div">
      <div class="wisdom-title">
        <span>行业智库</span>
      </div>
      <div class="wisdom-people">
        <div :class="index == 0 ? 'blue' : 'white' ">
          <div :class="index == 0 ? 'circleBlue' : 'circleWhite'">
            <img :src="firstTankPic" alt="">
          </div>
          <div :class="index == 0 ? 'blueName' : 'whiteName'"><span>{{firstTankName ? firstTankName : '\u00a0'}}</span></div>
          <div :class="index == 0 ? 'blueNameSub' : 'whiteNameSub'"><span>{{firstTankPost ? firstTankPost : '\u00a0'}}</span></div>
          <div v-if="index == 0">
            <hr>
            <div class="blueContent">
              <!--{{firstTankIntroduce}}-->
              <!--内容过长显示省略号-->
              <text-ellipsis :text="firstTankIntroduce" :height="50" style="width: 100%">
                <template slot="more">...</template>
              </text-ellipsis>
            </div>
            <div class="blueDetail" @click="wisdomDetail(firstTankId)">
              <span>查看详情</span>
              <img src="@/assets/img/detail.png" alt="" style="margin-left: 5px">
            </div>
          </div>
        </div>

        <div :class="index == 1 ? 'blue' : 'white' ">
          <div :class="index == 1 ? 'circleBlue' : 'circleWhite'">
            <img :src="secondTankPic" alt="">
          </div>
          <div :class="index == 1 ? 'blueName' : 'whiteName'"><span>{{secondTankName}}</span></div>
          <div :class="index == 1 ? 'blueNameSub' : 'whiteNameSub'">{{secondTankPost}}</div>
          <div v-if="index == 1">
            <hr>
            <div class="blueContent">
              <!--{{secondTankIntroduce}}-->
              <!--内容过长显示省略号-->
              <text-ellipsis :text="secondTankIntroduce" :height="50" style="width: 100%">
                <template slot="more">...</template>
              </text-ellipsis>
            </div>
            <div class="blueDetail" @click="wisdomDetail(secondTankId)">
              <span>查看详情</span>
              <img src="@/assets/img/detail.png" alt="" style="margin-left: 5px">
            </div>
          </div>
        </div>

        <div :class="index == 2 ? 'blue' : 'white' ">
          <div :class="index == 2 ? 'circleBlue' : 'circleWhite'">
            <img :src="thirdTankPic" alt="">
          </div>
          <div :class="index == 2 ? 'blueName' : 'whiteName'"><span>{{thirdTankName}}</span></div>
          <div :class="index == 2 ? 'blueNameSub' : 'whiteNameSub'">{{thirdTankPost}}</div>
          <div v-if="index == 2">
            <hr>
            <div class="blueContent">
              <!--{{thirdTankPost}}-->
              <!--内容过长显示省略号-->
              <text-ellipsis :text="thirdTankPost" :height="50" style="width: 100%">
                <template slot="more">...</template>
              </text-ellipsis>
            </div>
            <div class="blueDetail" @click="wisdomDetail(thirdTankId)">
              <span>查看详情</span>
              <img src="@/assets/img/detail.png" alt="" style="margin-left: 5px">
            </div>
          </div>
        </div>

        <div :class="index == 3 ? 'blue' : 'white' ">
          <div :class="index == 3 ? 'circleBlue' : 'circleWhite'">
            <img :src="fourthTankPic" alt="">
          </div>
          <div :class="index == 3 ? 'blueName' : 'whiteName'"><span>{{fourthTankName}}</span></div>
          <div :class="index == 3 ? 'blueNameSub' : 'whiteNameSub'">{{fourthTankPost}}</div>
          <div v-if="index == 3">
            <hr>
            <div class="blueContent">
              <!--{{fourthTankIntroduce}}-->
              <!--内容过长显示省略号-->
              <text-ellipsis :text="fourthTankIntroduce" :height="50" style="width: 100%">
                <template slot="more">...</template>
              </text-ellipsis>
            </div>
            <div class="blueDetail" @click="wisdomDetail(fourthTankId)">
              <span>查看详情</span>
              <img src="@/assets/img/detail.png" alt="" style="margin-left: 5px">
            </div>
          </div>
        </div>

      </div>
      <!--查看更多按钮-->
      <div class="moreBtn-div" @click="toDetail">
        <button class="moreBtn">查看更多 >></button>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import textEllipsis from '@/components/textellipsis'

export default {
  name: 'wisdom',
  components: { textEllipsis },
  data () {
    return {
      index: 0,
      wisdomList: [],
      firstTankName: '',
      firstTankPost: '',
      firstTankIntroduce: '',
      firstTankPic: '',
      firstTankId: '',
      secondTankName: '',
      secondTankPost: '',
      secondTankIntroduce: '',
      secondTankPic: '',
      secondTankId: '',
      thirdTankName: '',
      thirdTankPost: '',
      thirdTankIntroduce: '',
      thirdTankPic: '',
      thirdTankId: '',
      fourthTankName: '',
      fourthTankPost: '',
      fourthTankIntroduce: '',
      fourthTankPic: '',
      fourthTankId: '',
    }
  },
  methods: {
    //接口调用
    getWisdomData () {
      axios({
        method: 'get',
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/api/portal/think/queryPeopleList?isShow=1',
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.wisdomList = res.data.data
          for (let i = 0; i < this.wisdomList.length; i++) {
            this.wisdomList[i].img = this.wisdomList[i].tankPicUrl || '';
          }
          if (this.wisdomList.length >= 4) {
            this.firstTankName = this.wisdomList[0].tankName ? this.wisdomList[0].tankName : ''
            this.firstTankPost = this.wisdomList[0].tankPost ? this.wisdomList[0].tankPost : ''
            this.firstTankIntroduce = this.wisdomList[0].tankIntroduce ? this.wisdomList[0].tankIntroduce : ''
            this.firstTankPic = this.wisdomList[0].img ? this.wisdomList[0].img : ''
            this.firstTankId = this.wisdomList[0].tankId ? this.wisdomList[0].tankId : ''
            this.secondTankName = this.wisdomList[1].tankName ? this.wisdomList[1].tankName : ''
            this.secondTankPost = this.wisdomList[1].tankPost ? this.wisdomList[1].tankPost : ''
            this.secondTankIntroduce = this.wisdomList[1].tankIntroduce ? this.wisdomList[1].tankIntroduce : ''
            this.secondTankPic = this.wisdomList[1].img ? this.wisdomList[1].img : ''
            this.secondTankId = this.wisdomList[1].tankId ? this.wisdomList[1].tankId : ''
            this.thirdTankName = this.wisdomList[2].tankName ? this.wisdomList[2].tankName : ''
            this.thirdTankPost = this.wisdomList[2].tankPost ? this.wisdomList[2].tankPost : ''
            this.thirdTankIntroduce = this.wisdomList[2].tankIntroduce ? this.wisdomList[2].tankIntroduce : ''
            this.thirdTankPic = this.wisdomList[2].img ? this.wisdomList[2].img : ''
            this.thirdTankId = this.wisdomList[2].tankId ? this.wisdomList[2].tankId : ''
            this.fourthTankName = this.wisdomList[3].tankName ? this.wisdomList[3].tankName : ''
            this.fourthTankPost = this.wisdomList[3].tankPost ? this.wisdomList[3].tankPost : ''
            this.fourthTankIntroduce = this.wisdomList[3].tankIntroduce ? this.wisdomList[3].tankIntroduce : ''
            this.fourthTankPic = this.wisdomList[3].img ? this.wisdomList[3].img : ''
            this.fourthTankId = this.wisdomList[3].tankId ? this.wisdomList[3].tankId : ''
          }
        } else {
          this.$message.warning('请求失败')
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    //行业智库点击进入详情页
    wisdomDetail (item) {
      this.$router.push({
        path: '/thinkTanks/thinkTanksDetails',
        query: {
          tankId: item
        }
      })
    },

    //查看更多按钮
    toDetail () {
      this.$router.push({
        path: '/thinkTanks/thinkTanksDetail'
      })
    }
  },
  mounted () {
    this.getWisdomData()
    // 每2秒切换一次
    this.timers = setInterval(() => {
      if (this.index < 4) {
        this.index++
      }
      if (this.index == 4) {
        this.index = 0
      }
    }, 2000)
  }
}
</script>

<style lang="less" scoped>
.wisdomContentDiv {
  text-align: center;
}

.wisdom-div {
  height: 630px;
  background: url(../../assets/img/leftBelow.png) no-repeat;
  background-position: left bottom;

  .wisdom-title {
    font-size: 30px;
    font-weight: bold;
    margin-top: 70px;
    letter-spacing: 3px;
  }

  .wisdom-people {
    height: 387px;
    margin-top: 50px;
    display: flex;
    justify-content: center;

    .circleBlue {
      border-radius: 100%;
      overflow: hidden;
      width: 114px;
      height: 114px;
      margin: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .circleWhite {
      border-radius: 100%;
      overflow: hidden;
      width: 114px;
      height: 114px;
      margin: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .blueName {
      color: #ffffff;
      font-size: 20px;
      font-weight: bold;
      margin-top: 10px;
    }

    .whiteName {
      font-size: 20px;
      font-weight: bold;
      margin-top: 10px;
      margin-bottom: 15px;
    }

    .blueNameSub {
      margin-top: 10px;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;
      padding: 0px 10px;
    }

    .whiteNameSub {
      margin-top: 10px;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #666666;
      padding: 0px 10px;
    }

    hr {
      width: 80px;
      color: #ffffff;
    }

    .blueContent {
      color: #ffffff;
      font-size: 15px;
      margin-top: 20px;
      padding: 0px 10px;
      text-align: left;
    }

    .blueDetail {
      color: #faa93f;
      margin-top: 20px;
    }

    .blueDetail:hover {
      cursor: pointer;
    }

    .shenName {
      font-size: 20px;
      font-weight: bold;
      margin-top: 10px;
      margin-bottom: 15px;
    }
  }

  .moreBtn-div {
    height: 38px;
    margin-top: 35px;

    .moreBtn {
      border: none;
      width: 110px;
      height: 38px;
      background: #186CF5;
      border-radius: 5px;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;
    }

    .moreBtn:hover {
      cursor: pointer;
    }
  }
}

.blue {
  width: 430px;
  height: 386px;
  box-shadow: 0 3px 12px 0 rgba(178, 178, 178, 0.26);
  border-radius: 5px;
  box-sizing: border-box;
  background: url(../../assets/img/background.png);
  margin-left: 10px;
  padding-top: 20px;
}

.white {
  width: 253px;
  height: 386px;
  background: #FFFFFF;
  box-shadow: 0 3px 12px 0 rgba(178, 178, 178, 0.26);
  border-radius: 5px;
  box-sizing: border-box;
  margin-left: 10px;
  padding-top: 50px;
}

@media screen and (max-width: 1366px) {
  .wisdomContentDiv {
    text-align: center;
  }

  .wisdom-div {
    height: 630px;
    background: url(../../assets/img/leftBelow.png) no-repeat;
    background-position: left bottom;

    .wisdom-title {
      font-size: 30px;
      font-weight: bold;
      margin-top: 70px;
      letter-spacing: 3px;
    }

    .wisdom-people {
      height: 387px;
      margin-left: 65px;
      margin-top: 50px;
      display: flex;

      .circleBlue {
        border-radius: 100%;
        overflow: hidden;
        width: 114px;
        height: 114px;
        margin: auto;
      }

      .circleWhite {
        border-radius: 100%;
        overflow: hidden;
        width: 114px;
        height: 114px;
        margin: auto;
      }

      .blueName {
        color: #ffffff;
        font-size: 20px;
        font-weight: bold;
        margin-top: 10px;
      }

      .whiteName {
        font-size: 20px;
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 15px;
      }

      .blueNameSub {
        margin-top: 10px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        padding: 0px 10px;
      }

      .whiteNameSub {
        margin-top: 10px;
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #666666;
        padding: 0px 10px;
      }

      hr {
        width: 80px;
        color: #ffffff;
      }

      .blueContent {
        color: #ffffff;
        font-size: 15px;
        margin-top: 20px;
        padding: 0px 10px;
        text-align: left;
      }

      .blueDetail {
        color: #faa93f;
        margin-top: 20px;
      }

      .blueDetail:hover {
        cursor: pointer;
      }

      .shenName {
        font-size: 20px;
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 15px;
      }
    }

    .moreBtn-div {
      height: 38px;
      margin-top: 35px;

      .moreBtn {
        border: none;
        width: 110px;
        height: 38px;
        background: #186CF5;
        border-radius: 5px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
      }

      .moreBtn:hover {
        cursor: pointer;
      }
    }
  }

  .blue {
    width: 430px;
    height: 386px;
    box-shadow: 0 3px 12px 0 rgba(178, 178, 178, 0.26);
    border-radius: 5px;
    box-sizing: border-box;
    background: url(../../assets/img/background.png);
    margin-left: 10px;
    padding-top: 20px;
  }

  .white {
    width: 253px;
    height: 386px;
    background: #FFFFFF;
    box-shadow: 0 3px 12px 0 rgba(178, 178, 178, 0.26);
    border-radius: 5px;
    box-sizing: border-box;
    margin-left: 10px;
    padding-top: 50px;
  }
}
</style>
