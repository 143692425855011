<template>
  <div>
    <!--顶部图片及视频-->
    <top-video></top-video>

    <!--在线业务-->
    <online-business></online-business>

    <!--新闻部分-->
    <news></news>

    <!--大厦，购票招商-->
    <edifice></edifice>

    <!--专委会部分-->
    <special-committee></special-committee>

    <!--视频动态-->
    <video-trends></video-trends>

    <!--大数据平台-->
    <mapData></mapData>

    <!--行业智库-->
    <!--11.20，需求变动，行业智库隐藏，会员风采移动到此处-->
    <!--<wisdom-div></wisdom-div>-->
    <!--会员风采-->
    <member></member>

    <!--地标书画展-->
    <painting></painting>

    <!--地标产业联盟-->
    <union></union>


    <!--协会荣誉-->
    <honor></honor>

    <!--友情链接-->
    <friendly-links></friendly-links>

    <!--客服-->
    <customer-service></customer-service>

  </div>
</template>

<script>
  import 'video.js/dist/video-js.css'
  import {videoPlayer} from 'vue-video-player'
  import topVideo from '@/components/indexComponents/topVideo.vue'
  import specialCommittee from '@/components/indexComponents/specialCommittee.vue'
  import news from '@/components/indexComponents/news.vue'
  import edifice from '@/components/indexComponents/edifice.vue'
  import videoTrends from '@/components/indexComponents/video.vue'
  import mapData from '@/components/indexComponents/map.vue'
  import wisdomDiv from '@/components/indexComponents/wisdom.vue'
  import onlineBusiness from '@/components/indexComponents/onlineBusiness.vue'
  import painting from '@/components/indexComponents/painting.vue'
  import union from '@/components/indexComponents/union.vue'
  import member from '@/components/indexComponents/member.vue'
  import honor from '@/components/indexComponents/honor.vue'
  import friendlyLinks from '@/components/indexComponents/friendlyLinks.vue'
  import customerService from '@/components/indexComponents/customerService.vue'

export default {
  name: "homeContent",
  data() {
    return {

    }
  },
  components: {
    videoPlayer, wisdomDiv, topVideo, specialCommittee, news, edifice, videoTrends, mapData, onlineBusiness, painting, union, member, honor, friendlyLinks, customerService
  },
  mounted() {
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>

</style>
