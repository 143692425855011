var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mapContentDiv"},[_c('div',{staticClass:"bigdata-div"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"map-div"},[_c('div',{staticClass:"map-div-left"},[_c('div',{staticClass:"map-left-top"},[_c('div',{staticClass:"left-top-num"},[_c('div',{staticClass:"map-left-top-num"},[_vm._v(" "+_vm._s(_vm.chinaData)+" ")]),_c('div',{staticClass:"map-left-top-unit"},[_vm._v(" 件 ")])]),_vm._m(2)]),_c('div',{staticClass:"map-left-center"},[_c('div',{staticClass:"left-center-num"},[_c('div',{staticClass:"map-left-center-num"},[_vm._v(" "+_vm._s(_vm.protectData)+" ")]),_c('div',{staticClass:"map-left-center-unit"},[_vm._v(" 件 ")])]),_vm._m(3)]),_c('div',{staticClass:"map-left-bottom"},[_c('div',{staticClass:"left-bottom-num"},[_c('div',{staticClass:"map-left-bottom-num"},[_vm._v(" "+_vm._s(_vm.agrData)+" ")]),_c('div',{staticClass:"map-left-bottom-unit"},[_vm._v(" 个 ")])]),_vm._m(4)])]),_c('div',{staticClass:"center-div"},[_c('div',{ref:"charts",staticClass:"map-div-center"}),_vm._m(5)]),_c('div',{staticClass:"map-div-right"},[_c('div',{staticClass:"map-right-top"},[_c('div',{staticClass:"right-top-num"},[_c('div',{staticClass:"map-right-top-num"},[_vm._v(" "+_vm._s(_vm.brandData)+" ")]),_c('div',{staticClass:"map-right-top-unit"},[_vm._v(" 件 ")])]),_vm._m(6)]),_c('div',{staticClass:"map-right-center"},[_c('div',{staticClass:"right-center-num"},[_c('div',{staticClass:"map-right-center-num"},[_vm._v(" "+_vm._s(_vm.eachData)+" ")]),_c('div',{staticClass:"map-right-center-unit"},[_vm._v(" 个 ")])]),_vm._m(7)]),_c('div',{staticClass:"map-right-bottom"},[_c('div',{staticClass:"right-bottom-num"},[_c('div',{staticClass:"map-right-bottom-num"},[_vm._v(" "+_vm._s(_vm.goodData)+" ")]),_c('div',{staticClass:"map-right-bottom-unit"},[_vm._v(" 个 ")])]),_vm._m(8)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bigdata-title"},[_c('span',[_vm._v(" 山东省地理标志大数据平台 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bigdata-subtitle"},[_c('p',[_vm._v(" 山东省地理标志大数据平台为地理标志产业提供大数据服务和应用，提供开放、透明的信息窗口，提供精准的数据和洞察，为地标产业提供全面的数据支持、战略指导 ")]),_c('p',[_vm._v(" 和决策支持。促进地理标志产业品牌建设，推动地理标志产业发展。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map-left-top-text"},[_c('span',[_vm._v("在华已注册地理标志")]),_c('br'),_c('span',{staticStyle:{"float":"left"}},[_vm._v("证明/集体商标")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map-left-center-text"},[_c('span',{staticStyle:{"float":"left"}},[_vm._v("地理标志保护产品")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map-left-bottom-text"},[_c('span',{staticStyle:{"float":"left"}},[_vm._v("农产品地理标志")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map-center-bottom"},[_c('span',[_vm._v("数据截止:2021年10月;数据来源:2022年《世界知识产权指标》报告")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map-right-top-text"},[_c('span',{staticStyle:{"float":"left"}},[_vm._v("地理标志证明/集体")]),_c('br'),_c('span',{staticStyle:{"float":"left"}},[_vm._v("商标")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map-right-center-text"},[_c('span',[_vm._v("中欧互认互保地理标志")]),_c('br'),_c('span',{staticStyle:{"float":"left"}},[_vm._v("产品")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map-right-bottom-text"},[_c('span',[_vm._v("“好品山东”地理标志")]),_c('br'),_c('span',{staticStyle:{"float":"left"}},[_vm._v("产品")])])
}]

export { render, staticRenderFns }