<!--专委会-->
<template>
  <div class="specialCommDiv">
    <div class="committee-div">
      <div class="committee">

        <div v-for="(item, index) in committeeList" :key="index" class="committee-card" @click="committeeClick(item, index)">
          <div class="committee-card-title">
            {{item.title}}
          </div>
          <div class="speciality">专委会</div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "specialCommittee",
  data() {
      return {
        //专委会部分
        committeeList: [{
          title: '知识产权',
        }, {
          title: '品牌运营',
        }, {
          title: '法律服务',
        }, {
          title: '标准化',
        }, {
          title: '人才培育',
        }, {
          title: '国际合作',
        }],

      }
  },
  methods: {
    //专委会点击
    committeeClick(item, index) {
      sessionStorage.setItem('committeeContentType', index + 1) // 对应字典值
      sessionStorage.setItem('committeeContentTitle', item.title + '专委会')
      this.$router.push({
        path: '/committee/index',
      });
    },
  }
}
</script>

<style scoped lang="less">
  .specialCommDiv {
    text-align: center;
  }
  .committee-div {
    //margin: 0px 350px;
  .committee {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 35px;
    height: 90px;
  .committee-card {
    margin-right: 7px;
    margin-left: 7px;
    width: 186px;
    height: 90px;
    box-shadow: 0 3px 12px 0 rgba(178, 178, 178, 0.26);
    border-radius: 5px;
    box-sizing: border-box;
  .committee-card-title {
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
    background: transparent;
    font-size: 18px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
  }
  .speciality {
    text-align: left;
    margin-left: 20px;
    background: transparent;
    font-size: 18px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
  }
  }
  .committee-card:hover {
    cursor: pointer;
  }
  :nth-child(1) {
    background: url(../../assets/img/intellectual.png);
  //background: #e2eefe;
  .committee-card-title {
    color: #0B5CDF;
  }
  .speciality {
    color: #0B5CDF;
  }
  }
  :nth-child(2) {
    background: url(../../assets/img/brand.png);
  .committee-card-title {
    color: #E37200;
  }
  .speciality {
    color: #E37200;
  }
  }
  :nth-child(3) {
    background: url(../../assets/img/law.png);
  .committee-card-title {
    color: #ED521C;
  }
  .speciality {
    color: #ED521C;
  }
  }
  :nth-child(4) {
    background: url(../../assets/img/standard.png);
  .committee-card-title {
    color: #009B6B;
  }
  .speciality {
    color: #009B6B;
  }
  }
  :nth-child(5) {
    background: url(../../assets/img/talent.png);
  .committee-card-title {
    color: #0B5CDF;
  }
  .speciality {
    color: #0B5CDF;
  }
  }
  :nth-child(6) {
    background: url(../../assets/img/international.png);
  .committee-card-title {
    color: #E37200;
  }
  .speciality {
    color: #E37200;
  }
  }
  }
  }

  @media screen and (max-width: 1366px) {
    .specialCommDiv {
      text-align: center;
    }
    .committee-div {
      margin: 0px 100px;
      .committee {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 35px 10px 0px 10px;
        height: 90px;
        .committee-card {
          width: 186px;
          height: 90px;
          box-shadow: 0 3px 12px 0 rgba(178, 178, 178, 0.26);
          border-radius: 5px;
          box-sizing: border-box;
          .committee-card-title {
            text-align: left;
            margin-left: 20px;
            margin-top: 20px;
            background: transparent;
            font-size: 18px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
          }
          .speciality {
            text-align: left;
            margin-left: 20px;
            background: transparent;
            font-size: 18px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
          }
        }
        .committee-card:hover {
          cursor: pointer;
        }
        :nth-child(1) {
          background: url(../../assets/img/intellectual.png);
          //background: #e2eefe;
          .committee-card-title {
            color: #0B5CDF;
          }
          .speciality {
            color: #0B5CDF;
          }
        }
        :nth-child(2) {
          background: url(../../assets/img/brand.png);
          .committee-card-title {
            color: #E37200;
          }
          .speciality {
            color: #E37200;
          }
        }
        :nth-child(3) {
          background: url(../../assets/img/law.png);
          .committee-card-title {
            color: #ED521C;
          }
          .speciality {
            color: #ED521C;
          }
        }
        :nth-child(4) {
          background: url(../../assets/img/standard.png);
          .committee-card-title {
            color: #009B6B;
          }
          .speciality {
            color: #009B6B;
          }
        }
        :nth-child(5) {
          background: url(../../assets/img/talent.png);
          .committee-card-title {
            color: #0B5CDF;
          }
          .speciality {
            color: #0B5CDF;
          }
        }
        :nth-child(6) {
          background: url(../../assets/img/international.png);
          .committee-card-title {
            color: #E37200;
          }
          .speciality {
            color: #E37200;
          }
        }
      }
    }
  }

</style>
