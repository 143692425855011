<!--会员风采-->
<template>
  <div class="memberContentDiv">
    <div class="member-div">
      <div class="member-title">
        <span>会员风采</span>
      </div>
      <div class="memberNewsDiv">
        <div class="member-news">
          <el-carousel :interval="5000" arrow="always">
            <el-carousel-item v-for="(item,index) in styleList" :key="index">
              <div class="style-card" @click="styleClick()">
                <div class="style-img"><img :src="item.contentImg" alt/></div>
                <div class="style-item-content">
                  <div class="style-title">{{ item.contentTitle }}</div>
                  <div class="style-content">
                    <text-ellipsis :text="item.contentOverview" :height="175" style="width: 100%">
                      <template slot="more">...</template>
                    </text-ellipsis>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="member-name">
        <div v-for="(item, index) in memberList" :key="index" class="member-card" @click="memberClick(item)">
          <div class="memberpeople-img">
            <img :src="item.url" alt/>
          </div>
          <div class="memberpeople-title">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import textEllipsis from '@/components/textellipsis'
export default {
  name: 'member',
  components: { textEllipsis },
  data () {
    return {
      styleList: [],

      //会员风采
      memberList: [{
        url: require('@/assets/img/vicePresident.png'),
        title: '副会长',
      }, {
        url: require('@/assets/img/supervisor.png'),
        title: '监事',
      }, {
        url: require('@/assets/img/director.png'),
        title: '理事',
      }, {
        url: require('@/assets/img/fellow.png'),
        title: '会员',
      }],
    }
  },
  methods: {
    getMember() {
      let param = {
        id: "744966206007812096", //传值写死
      }
      axios({
        method: "post",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/api/portal/graphicdisplay/queryGraphic',
        data: param
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.styleList = res.data.data
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },


    styleClick () {
      this.$router.push({
        path: '/member/memberDetail'
      })
    },

    memberClick (item) {
      if (item.title == "监事") {
        return
      } else {
        this.$router.push({
          path: '/giia/member',
          query: {
            name: item.title,
          }
        })
      }
    }
  },
  mounted() {
    this.getMember()
  }
}
</script>

<style scoped lang="less">
.memberContentDiv {
  text-align: center;
}

.member-div {
  height: 630px;
  background: url(../../assets/img/member.png);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden; //解决子div设置margin-top使父div也跟着向下移动的问题
  .member-title {
    margin-top: 63px;
    font-size: 34px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 51px;
  }

  .memberNewsDiv {
    display: flex;
    justify-content: center;
    .member-news {
      //margin: 56px 350px;
      margin-top: 56px;
      height: 297px;
      width: 77rem;

      .style-card {
        width: 1011px;
        height: 230px;
        background: #E7F0FF;
        //border-radius: 8px;
        margin: auto;
        display: flex;
        padding: 35px;

        img {
          width: 438px;
          height: 227px;
        }

        .style-item-content {
          margin-left: 32px;

          .style-title {
            font-size: 20px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #333333;
            line-height: 30px;
            text-align: left;
            overflow: hidden;//超出隐藏
            text-overflow: ellipsis;//显示省略号
            white-space: nowrap;//禁止文本自动换行
            width: 530px;
          }

          .style-content {
            margin-top: 13px;
            text-align: left;
            font-size: 16px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #555555;
            line-height: 30px;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
          }
        }
      }

      .style-card:hover {
        cursor: pointer;
      }
    }
  }


  .member-name {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    height: 85px;

    .member-card {
      transition: all .2s ease;
      width: 236px;
      height: 81px;
      box-shadow: 0 3px 12px 0 rgba(178, 178, 178, 0.26);
      border-radius: 5px;
      box-sizing: border-box;
      display: flex;
      padding: 30px 70px;
      margin-right: 20px;
      margin-left: 20px;

      .memberpeople-img {
        background: transparent;

        img {
          background: transparent;
        }
      }

      .memberpeople-title {
        background: transparent;
        margin-top: 2px;
        margin-left: 10px;
        font-size: 18px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 27px;
      }
    }

    .member-card:hover {
      cursor: pointer;
    }

    :nth-child(1) {
      background: url(../../assets/img/memberBlue.png);
    }

    :nth-child(2) {
      background: url(../../assets/img/memberOrange.png);
    }

    :nth-child(3) {
      background: url(../../assets/img/memberGreen.png);
    }

    :nth-child(4) {
      background: url(../../assets/img/memberPurple.png);
    }
  }
}

//调整走马灯箭头-左箭头
::v-deep .el-carousel__arrow--left {
  font-size: 25px;
  color: #ffffff;
  background-color: transparent;
  border: 1px solid #ffffff;
}

//右箭头
::v-deep .el-carousel__arrow--right {
  font-size: 25px;
  color: #ffffff;
  background-color: transparent;
  border: 1px solid #ffffff;
}

/*@media screen and (max-width: 1366px) {
  .memberContentDiv {
    text-align: center;
  }

  .member-div {
    height: 630px;
    background: url(../../assets/img/member.png);
    overflow: hidden; //解决子div设置margin-top使父div也跟着向下移动的问题
    .member-title {
      margin-top: 63px;
      font-size: 34px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 51px;
    }

    .member-news {
      margin: 56px 80px;
      height: 297px;

      .style-card {
        width: 1011px;
        height: 295px;
        background: #E7F0FF;
        //border-radius: 8px;
        margin: auto;
        display: flex;
        padding: 35px;

        img {
          width: 438px;
          height: 227px;
        }

        .style-item-content {
          margin-left: 32px;

          .style-title {
            font-size: 20px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #333333;
            line-height: 30px;
            text-align: left;
          }

          .style-content {
            margin-top: 13px;
            text-align: left;
            font-size: 16px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #555555;
            line-height: 30px;
          }
        }
      }

      .style-card:hover {
        cursor: pointer;
      }
    }

    .member-name {
      display: flex;
      justify-content: space-between;
      margin: 60px 100px 0px 100px;
      height: 85px;

      .member-card {
        transition: all .2s ease;
        width: 236px;
        height: 81px;
        box-shadow: 0 3px 12px 0 rgba(178, 178, 178, 0.26);
        border-radius: 5px;
        box-sizing: border-box;
        display: flex;
        padding: 30px 70px;

        .memberpeople-img {
          background: transparent;

          img {
            background: transparent;
          }
        }

        .memberpeople-title {
          background: transparent;
          margin-top: 2px;
          margin-left: 10px;
          font-size: 18px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 27px;
        }
      }

      .member-card:hover {
        cursor: pointer;
      }

      :nth-child(1) {
        background: url(../../assets/img/memberBlue.png);
      }

      :nth-child(2) {
        background: url(../../assets/img/memberOrange.png);
      }

      :nth-child(3) {
        background: url(../../assets/img/memberGreen.png);
      }

      :nth-child(4) {
        background: url(../../assets/img/memberPurple.png);
      }
    }
  }

  //调整走马灯箭头-左箭头
  ::v-deep .el-carousel__arrow--left {
    font-size: 25px;
    color: #ffffff;
    background-color: transparent;
    border: 1px solid #ffffff;
  }

  //右箭头
  ::v-deep .el-carousel__arrow--right {
    font-size: 25px;
    color: #ffffff;
    background-color: transparent;
    border: 1px solid #ffffff;
  }
}*/

</style>
