<!--友情链接-->
<template>
  <div class="friendlyLinksDiv">
    <div class="links-div">
      <div class="links-title">
        <span>友情链接</span>
      </div>
      <div class="links-content">
        <div v-for="(item, index) in linksList" :key="index" class="links-card" @click="toPath(item.linkUrl)">
          <div>
            <img :src="item.img" alt height="60px"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'friendlyLinks',
  data () {
    return {
      // 友情链接
      linksList: []
    }
  },
  mounted () {
    this.getLink()
  },
  methods: {
    // 友情链接点击跳转
    toPath (path) {
      // let newPath = window.location.protocol + "//" + path //返回值http:
      window.open(path, '_blank')
    },

    // 调用友情链接接口
    getLink () {
      axios({
        method: 'post',
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/api/portal/contentCategory/queryFriendLink'
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.linksList = res.data.data

          for (let i = 0; i < this.linksList.length; i++) {
            this.linksList[i].img = this.linksList[i].logo
          }
        } else {
          this.$message.warning('请求失败')
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    }

  }
}
</script>

<style scoped lang="less">
  .friendlyLinksDiv {
    text-align: center;
  }
  .links-div {
    .links-title {
      font-size: 30px;
      font-weight: bold;
      letter-spacing: 3px;
    }
    .links-content {
      display: flex;
      justify-content: center;
      margin-top: 60px;
      margin-bottom: 60px;
      .links-card {
        cursor: pointer;
        margin-left: 10px;
        margin-bottom: 10px;
        transition: all .2s ease;
        width: 280px;
        height: 85px;
        background: #FFFFFF;
        box-shadow: 0 3px 12px 0 rgba(178, 178, 178, 0.26);
        border-radius: 5px;
        box-sizing: border-box;
        padding: 21px 10px;
        img {
          width: 230px;
          height: 42px;
        }
      }
    }
  }
  @media screen and (max-width: 1366px) {
    .friendlyLinksDiv {
      text-align: center;
    }
    .links-div {
      .links-title {
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 3px;
      }
      .links-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 60px 95px;
        .links-card {
          cursor: pointer;
          margin-left: 10px;
          margin-bottom: 10px;
          transition: all .2s ease;
          width: 380px;
          height: 100px;
          background: #FFFFFF;
          box-shadow: 0 3px 12px 0 rgba(178, 178, 178, 0.26);
          border-radius: 5px;
          box-sizing: border-box;
          padding: 21px 10px;
          img {
            width: 355px;
            height: 60px;
          }
        }
      }
    }
  }

</style>
