<!--地图-->
<template>
  <div class="mapContentDiv">
    <div class="bigdata-div">
      <div class="bigdata-title">
        <span>
          山东省地理标志大数据平台
        </span>
      </div>
      <div class="bigdata-subtitle">
        <p>
          山东省地理标志大数据平台为地理标志产业提供大数据服务和应用，提供开放、透明的信息窗口，提供精准的数据和洞察，为地标产业提供全面的数据支持、战略指导
        </p>
        <p>
          和决策支持。促进地理标志产业品牌建设，推动地理标志产业发展。
        </p>
      </div>
      <!--地图部分-->
      <div class="map-div">
        <div class="map-div-left">
          <div class="map-left-top">
            <div class="left-top-num">
              <div class="map-left-top-num">
                {{chinaData}}
              </div>
              <div class="map-left-top-unit">
                件
              </div>
            </div>
            <div class="map-left-top-text">
              <span>在华已注册地理标志</span><br>
              <span style="float: left">证明/集体商标</span>
            </div>
          </div>
          <div class="map-left-center">
            <div class="left-center-num">
              <div class="map-left-center-num">
                {{protectData}}
              </div>
              <div class="map-left-center-unit">
                件
              </div>
            </div>
            <div class="map-left-center-text">
              <span style="float: left">地理标志保护产品</span>
            </div>
          </div>
          <div class="map-left-bottom">
            <div class="left-bottom-num">
              <div class="map-left-bottom-num">
                {{agrData}}
              </div>
              <div class="map-left-bottom-unit">
                个
              </div>
            </div>
            <div class="map-left-bottom-text">
              <span style="float: left">农产品地理标志</span>
            </div>
          </div>
        </div>
        <div class="center-div">
          <div ref="charts" class="map-div-center"></div>
          <div class="map-center-bottom">
            <span>数据截止:2021年10月;数据来源:2022年《世界知识产权指标》报告</span>
          </div>
        </div>
        <div class="map-div-right">
          <div class="map-right-top">
            <div class="right-top-num">
              <div class="map-right-top-num">
                {{brandData}}
              </div>
              <div class="map-right-top-unit">
                件
              </div>
            </div>
            <div class="map-right-top-text">
              <span style="float: left">地理标志证明/集体</span><br>
              <span style="float: left">商标</span>
            </div>
          </div>
          <div class="map-right-center">
            <div class="right-center-num">
              <div class="map-right-center-num">
                {{eachData}}
              </div>
              <div class="map-right-center-unit">
                个
              </div>
            </div>
            <div class="map-right-center-text">
              <span>中欧互认互保地理标志</span><br>
              <span style="float: left">产品</span>
            </div>
          </div>
          <div class="map-right-bottom">
            <div class="right-bottom-num">
              <div class="map-right-bottom-num">
                {{goodData}}
              </div>
              <div class="map-right-bottom-unit">
                个
              </div>
            </div>
            <div class="map-right-bottom-text">
              <span>“好品山东”地理标志</span><br>
              <span style="float: left">产品</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入山东省的地图数据
import shandong from '@/assets/json/shandong.json'
import axios from 'axios'
export default {
  name: "map",
  data() {
      return {
        chinaData: "", //在华已注册
        protectData: "", //地理标志保护
        agrData: "", //农产品
        brandData: "", //商标
        eachData: "", //中欧互认
        goodData: "", //好品山东
        cityName: "",
        giRegionId: '', //城市id

      }
  },
  mounted() {
    this.getMapData()
    this.getTotalData()

  },
  methods: {
    //地图部分接口
    getMapData() {
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/map/queryregionlist",
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.mapList = res.data.data
          this.getMap()

        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    getTotalData() {
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/map/total",
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.eachData = res.data.data.zohrhbdlbzcp
          this.protectData = res.data.data.dlbzbhcp
          this.brandData = res.data.data.dlbzsb
          this.agrData = res.data.data.ncp
          this.goodData = res.data.data.hpsdbz
          this.chinaData = res.data.data.zhyzcdlbzzm

        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    getMap() {
      const that = this
      const charts = this.$echarts.init(this.$refs["charts"]); //echarts图表挂载到对应的盒子上
      const data = this.mapList;
      const color = ['#66a1ff', '#8fbfff', '#a9daff'] //每个市的颜色

      let option = {
        tooltip: {
          show: true, //提示框
          trigger: "item",
        },
        geo: {
          show: true,
          map: 'shandong',
          zoom: 1.2, // 当前视角的缩放比例
          left:90,
          boxWidth: 156,
          regionHeight: 7, //地图厚度
          itemStyle: {
            //整体板块的样式
            opacity: 1, //透明度
            borderWidth: 1.5, // 边框宽度
            borderColor: "#052551", // 边框颜色
          },
          label: {
            show: true,
            textStyle: {
              color: "#000000", //地图初始化区域字体颜色
              fontSize: 12,
              opacity: 1,
            },
            formatter: function (params) {
              return params.name
            },
          },
          tooltip: {
            show: true, //提示框
            trigger: "item",
            formatter: function(params) { // 鼠标经过的回调函数
              let obj = {}
              for (let i = 0; i < data.length; i++) {
                if (data[i].giRegionName == params.name) {
                  obj = data[i]
                  break
                }
              }
              return `<div class="cardContainer">
                        <div style="text-align: center">
                          <div class="titleRow">
                            ${params.name}
                          </div>
                        </div>
                        <div class="dataListClass">
                          <div class="dataRow">
                            <div class="flowRowName" style="width: 180px;">中欧互认互保地理标志产品：</div>
                            <div class="flowRowValue">${obj.zohrhbdlbzcp}</div>
                            <div>个</div>
                          </div>
                          <div class="dataRow">
                            <div class="flowRowName" style="width: 125px;">地理标志保护产品：</div>
                            <div class="flowRowValue">${obj.dlbzbhcp}</div>
                            <div>个</div>
                          </div>
                          <div class="dataRow" >
                            <div class="flowRowName" style="width: 100px;">地标标志商标：</div>
                            <div class="flowRowValue">${obj.dlbzsb}</div>
                            <div>件</div>
                          </div>
                          <div class="dataRow" >
                            <div class="flowRowName" style="width: 100px;">好品山东地标：</div>
                            <div class="flowRowValueName">${obj.hpsdbz}</div>
                          </div>
                        </div>
                      </div>`
            },
          },

          emphasis: {
            //当鼠标放上去的状态
            /*label: {
              show: true,
            },*/
            itemStyle: {
              color: "#fecd7e",//鼠标悬浮颜色
            },
          },

          regions: [ // 处理各地级市颜色
            { name: '威海市', itemStyle: { color: color[1] } },
            { name: '烟台市', itemStyle: { color: color[1] } },
            { name: '青岛市', itemStyle: { color: color[1] } },
            { name: '日照市', itemStyle: { color: color[1] } },
            { name: '临沂市', itemStyle: { color: color[1] } },
            { name: '枣庄市', itemStyle: { color: color[1] } },
            { name: '潍坊市', itemStyle: { color: color[1] } },

            { name: '滨州市', itemStyle: { color: color[2] } },
            { name: '淄博市', itemStyle: { color: color[2] } },
            { name: '泰安市', itemStyle: { color: color[2] } },

            { name: '济南市', itemStyle: { color: color[0] } },
            { name: '德州市', itemStyle: { color: color[0] } },
            { name: '聊城市', itemStyle: { color: color[0] } },
            { name: '济宁市', itemStyle: { color: color[0] } },
            { name: '菏泽市', itemStyle: { color: color[0] } },
            { name: '东营市', itemStyle: { color: color[0] } },
          ]
        },

        /*series : [ //又加了一层用于鼠标悬浮显示提示
          {
            type: 'map',
            map: 'shandong',
            roam: false,
            left:0,
            boxWidth: 156,
            regionHeight: 7, //地图厚度
          },
        ]*/

      };

      // 地图注册，第一个参数的名字必须和option.geo.map一致
      this.$echarts.registerMap("shandong", shandong)

      charts.setOption(option);//绘制图标

      charts.on("click", function (params) { //点击事件
        that.cityName = params.name
        //console.log("this", this) //注意echarts中this的指向
        that.toMapPath(that.cityName)
      });
    },
    toMapPath(city) {
      //根据城市名称查询对应的id
      for (let i = 0; i < this.mapList.length; i++) {
        if (this.mapList[i].giRegionName == city) {
          this.giRegionId = this.mapList[i].giRegionId
        }
      }
      this.$router.push({
        path: `/map/giMap`,
        query: {
          cityName: city,
          giRegionId: this.giRegionId,
        }
      })
    },
  }
}
</script>

<style scoped lang="less">
  .mapContentDiv {
    text-align: center;
  }
  .bigdata-div {
    height: 1104px;
    background-color: #f4f9ff;
    overflow: hidden; //解决子div设置margin-top使父div也跟着向下移动的问题
    .bigdata-title {
      margin-top: 68px;
      font-size: 34px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #2E2E2E;
      line-height: 51px;
    }
  .bigdata-subtitle {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 30px;
  }
  .map-div {
    display: flex;
    overflow: hidden;
    background: url("../../assets/img/bj.png");
    height: 942px;
    width: 1410px;
    margin: auto;
  .map-div-left {
    margin-left: 130px;
    margin-top: 140px;
  .map-left-top {
  .left-top-num {
    display: flex;
  .map-left-top-num {
    font-size: 46px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #006AD7;
    line-height: 54px;
  }
  .map-left-top-unit {
    margin-top: 25px;
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
  }
  }
  .map-left-top-text {
    font-size: 18px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #333333;
    line-height: 26px;
  }
  }
  .map-left-center {
    margin-top: 110px;
    .left-center-num {
      display: flex;
      .map-left-center-num {
        font-size: 46px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #00BCC1;
        line-height: 54px;
      }
      .map-left-center-unit {
        margin-top: 25px;
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
      }
    }
    .map-left-center-text {
      font-size: 18px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      line-height: 26px;
    }
  }
  .map-left-bottom {
    margin-top: 110px;
  .left-bottom-num {
    display: flex;
  .map-left-bottom-num {
    font-size: 46px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #F7BA00;
    line-height: 54px;
  }
  .map-left-bottom-unit {
    margin-top: 25px;
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
  }
  }
  .map-left-bottom-text {
    font-size: 18px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #333333;
    line-height: 26px;
  }
  }
  }
  .center-div {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  .map-div-center {
    width: 799px;
    height: 506px;
    margin-top: 120px;
  }
  .map-center-bottom {
    margin-top: 75px;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #949DAB;
    line-height: 21px;
  .map-center-num {
    display: flex;
    justify-content: center;
  .center-num {
    font-size: 46px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #0096FF;
    line-height: 54px;
  }
  .map-center-unit {
    margin-top: 25px;
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
  }
  }
  .map-center-text {
    font-size: 18px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #333333;
    line-height: 26px;
  }
  }
  }
  .map-div-right {
    margin-top: 140px;
    margin-left: 40px;
  .map-right-top {
  .right-top-num {
    display: flex;
  .map-right-top-num {
    font-size: 46px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #00B488;
    line-height: 54px;
  }
  .map-right-top-unit {
    margin-top: 25px;
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
  }
  }
  .map-right-top-text {
    font-size: 18px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #333333;
    line-height: 26px;
  }
  }
  .map-right-center {
    margin-top: 110px;
    .right-center-num {
      display: flex;
      .map-right-center-num {
        font-size: 46px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #F48700;
        line-height: 54px;
      }
      .map-right-center-unit {
        margin-top: 25px;
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
      }
    }
    .map-right-center-text {
      font-size: 18px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      line-height: 26px;
    }
  }
  .map-right-bottom {
    margin-top: 100px;
  .right-bottom-num {
    display: flex;
  .map-right-bottom-num {
    font-size: 46px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #F48700;
    line-height: 54px;
  }
  .map-right-bottom-unit {
    margin-top: 25px;
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
  }
  }
  .map-right-bottom-text {
    font-size: 18px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #333333;
    line-height: 26px;
  }
  }
  }
  }
  .bigdata-bottom {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #949DAB;
    line-height: 21px;
    margin-top: -110px;
  }
  }
  //地图提示框样式
  ::v-deep .cardContainer {
    width: 269px;
    height: 160px;
    background-color: rgba(255, 255, 255, 0.5);
    .titleRow {
      width: 100%;
      height: auto;
      margin: 10px 0 7px;
      white-space: normal;
      word-break: break-all;
      font-size: 16px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #333333 ;
      line-height: 24px;
      background: #333333;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .dataListClass {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .dataRow {
      display: flex;
      flex-direction: row;
      margin: 4px 0;
    }
    .flowRowName {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
      line-height: 21px;
      //width: 180px;
      text-align: left;
      margin-left: 15px;
    }
    .flowRowValue {
      margin-right: 7px;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      color: #0366d7;
      font-weight: bold;
      line-height: 21px;
      white-space: normal;
      word-break: break-all;
      text-align: left;
    }
    .flowRowValueName {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      color: #333333;
      line-height: 21px;
      white-space: normal;
      word-break: break-all;
      text-align: left;
    }
  }

  @media screen and (max-width: 1366px) {
    .mapContentDiv {
      text-align: center;
    }
    .bigdata-div {
      height: 1104px;
      background-color: #f4f9ff;
      overflow: hidden; //解决子div设置margin-top使父div也跟着向下移动的问题
      .bigdata-title {
        margin-top: 68px;
        font-size: 34px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #2E2E2E;
        line-height: 51px;
      }
      .bigdata-subtitle {
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #666666;
        line-height: 30px;
      }
      .map-div {
        display: flex;
        overflow: hidden;
        background: url("../../assets/img/bj.png");
        height: 942px;
        width: 1410px;
        margin-left: -20px;
        .map-div-left {
          margin-left: 130px;
          margin-top: 140px;
          .map-left-top {
            .left-top-num {
              display: flex;
              .map-left-top-num {
                font-size: 46px;
                font-family: Arial-BoldMT, Arial;
                font-weight: normal;
                color: #006AD7;
                line-height: 54px;
              }
              .map-left-top-unit {
                margin-top: 25px;
                font-size: 16px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #666666;
                line-height: 24px;
              }
            }
            .map-left-top-text {
              font-size: 18px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #333333;
              line-height: 26px;
            }
          }
          .map-left-center {
            margin-top: 110px;
            .left-center-num {
              display: flex;
              .map-left-center-num {
                font-size: 46px;
                font-family: Arial-BoldMT, Arial;
                font-weight: normal;
                color: #00BCC1;
                line-height: 54px;
              }
              .map-left-center-unit {
                margin-top: 25px;
                font-size: 16px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #666666;
                line-height: 24px;
              }
            }
            .map-left-center-text {
              font-size: 18px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #333333;
              line-height: 26px;
            }
          }
          .map-left-bottom {
            margin-top: 110px;
            .left-bottom-num {
              display: flex;
              .map-left-bottom-num {
                font-size: 46px;
                font-family: Arial-BoldMT, Arial;
                font-weight: normal;
                color: #F7BA00;
                line-height: 54px;
              }
              .map-left-bottom-unit {
                margin-top: 25px;
                font-size: 16px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #666666;
                line-height: 24px;
              }
            }
            .map-left-bottom-text {
              font-size: 18px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #333333;
              line-height: 26px;
            }
          }
        }
        .center-div {
          display: flex;
          flex-direction: column;
          margin-left: 20px;
          .map-div-center {
            width: 799px;
            height: 506px;
            margin-top: 120px;
          }
          .map-center-bottom {
            margin-top: 75px;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #949DAB;
            line-height: 21px;
            .map-center-num {
              display: flex;
              justify-content: center;
              .center-num {
                font-size: 46px;
                font-family: Arial-BoldMT, Arial;
                font-weight: normal;
                color: #0096FF;
                line-height: 54px;
              }
              .map-center-unit {
                margin-top: 25px;
                font-size: 16px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #666666;
                line-height: 24px;
              }
            }
            .map-center-text {
              font-size: 18px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #333333;
              line-height: 26px;
            }
          }
        }
        .map-div-right {
          margin-top: 140px;
          margin-left: 40px;
          .map-right-top {
            .right-top-num {
              display: flex;
              .map-right-top-num {
                font-size: 46px;
                font-family: Arial-BoldMT, Arial;
                font-weight: normal;
                color: #00B488;
                line-height: 54px;
              }
              .map-right-top-unit {
                margin-top: 25px;
                font-size: 16px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #666666;
                line-height: 24px;
              }
            }
            .map-right-top-text {
              font-size: 18px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #333333;
              line-height: 26px;
            }
          }
          .map-right-center {
            margin-top: 110px;
            .right-center-num {
              display: flex;
              .map-right-center-num {
                font-size: 46px;
                font-family: Arial-BoldMT, Arial;
                font-weight: normal;
                color: #F48700;
                line-height: 54px;
              }
              .map-right-center-unit {
                margin-top: 25px;
                font-size: 16px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #666666;
                line-height: 24px;
              }
            }
            .map-right-center-text {
              font-size: 18px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #333333;
              line-height: 26px;
            }
          }
          .map-right-bottom {
            margin-top: 100px;
            .right-bottom-num {
              display: flex;
              .map-right-bottom-num {
                font-size: 46px;
                font-family: Arial-BoldMT, Arial;
                font-weight: normal;
                color: #F48700;
                line-height: 54px;
              }
              .map-right-bottom-unit {
                margin-top: 25px;
                font-size: 16px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #666666;
                line-height: 24px;
              }
            }
            .map-right-bottom-text {
              font-size: 18px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #333333;
              line-height: 26px;
            }
          }
        }
      }
      .bigdata-bottom {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #949DAB;
        line-height: 21px;
        margin-top: -110px;
      }
    }
    //地图提示框样式
    ::v-deep .cardContainer {
      width: 269px;
      height: 160px;
      background-color: rgba(255, 255, 255, 0.5);
      .titleRow {
        width: 100%;
        height: auto;
        margin: 10px 0 7px;
        white-space: normal;
        word-break: break-all;
        font-size: 16px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #333333 ;
        line-height: 24px;
        background: #333333;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .dataListClass {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .dataRow {
        display: flex;
        flex-direction: row;
        margin: 4px 0;
      }
      .flowRowName {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
        //width: 180px;
        text-align: left;
        margin-left: 15px;
      }
      .flowRowValue {
        margin-right: 7px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        color: #0366d7;
        font-weight: bold;
        line-height: 21px;
        white-space: normal;
        word-break: break-all;
        text-align: left;
      }
      .flowRowValueName {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        color: #333333;
        line-height: 21px;
        white-space: normal;
        word-break: break-all;
        text-align: left;
      }
    }
  }

</style>
