<!--新闻-->
<template>
  <div class="newsListDiv">
    <div class="news-div">
      <div class="news-div-left">
        <el-carousel class="carouselNews" indicator-position="inside">
          <el-carousel-item v-for="(item,index) in newsImgs" :key="index">
            <img :src="item.contentImg" alt="" class="top-img" @click="toDetail(item)">
            <div class="downShadowDiv">
              <div class="downShadowText">
                <span class="downSpanClass">{{item.contentTitle}}</span>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="news-div-right">
        <div class="notice-table">
          <div class="notice-item" v-for="(item, index) in noticeList" :key="index" @click="toDetail(item)">
            <div>
              <div class="notice-item-before"/>
              <div class="notice-item-title" :title="item.contentTitle">{{ item.contentTitle }}</div>
            </div>
            <div class="notice-date">{{ item.createTime }}</div>
          </div>
        </div>
      </div>
    </div>
    <!--查看更多按钮-->
    <div class="moreBtn-div">
      <button class="moreBtn" @click="moreBtnClick">查看更多 >></button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "news",
  data() {
    return {
      //新闻列表
      noticeList: [],
      //新闻轮播图图片
      newsImgs: [],
    }
  },
  mounted() {
    this.getArticle()
  },
  methods: {
    //跳转详情
    toDetail(item) {
      let pathObj = []
      pathObj.push({
          name: '首页',
          path: '/'
        },
        {
        name: '新闻详情',
        path: '/news/newsDetails'
      })

      this.$router.push({
        path: '/news/newsDetails',
        query: {
          pathObj: pathObj,
          contentId: item.contentId,
        }
      });
    },
  //文章查询
  getArticle() {
    axios({
      method: "get",
      url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/article/queryArticle",
    }).then(res => {
      if (res.data.code === 10000 || res.data.code === 200) {
        this.newsImgs = res.data.data
        this.noticeList = res.data.data
        for (let i = 0; i < this.newsImgs.length; i++) {
          //截取日期的年月日
          this.newsImgs[i].createTime = this.newsImgs[i].contentEditTime.slice(0, 10)
        }
      } else {
        this.$message.warning("请求失败")
      }
    }).catch(err => {
      this.$message.error('请求失败')
    })
  },
  //新闻部分查看更多按钮点击
  moreBtnClick() {
    this.$router.push({
      path: '/newsList'
    });
  },
  }
}
</script>

<style scoped lang="less">
  .newsListDiv {
    text-align: center;
  }
  .news-div {
    //margin: 0px 350px;
    height: 360px;
    display: flex;
    justify-content: center;
  .news-div-left {
    width: 50%;
    margin-top: 35px;
  .carouselNews {
    float: right;
    border-radius: 5px;
    height: 360px;
    width: 590px;
    .top-img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
    .downShadowDiv {
      height: 48px;
      background: rgba(0,0,0,0.4);
      border-radius: 0px 0px 5px 5px;
      position: relative;
      bottom: 53px;
      text-align: left;
      padding-left: 15px;
      padding-top: 16px;
      .downShadowText {
        width: 377px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .downSpanClass {
          font-size: 16px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          color: #FFFFFF;
        }
      }
    }
  }
  }
  .news-div-right {
    width: 50%;
    margin-top: 35px;
  .notice-table {
    float: left;
    margin-left: 4%;
  .notice-item {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 5px;
    height: 55px;
  .notice-item-title {
    position: relative;
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
    width: 432px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  // 为了控制在一行显示，多出字数用省略号代替，所以注释掉
  //&::before {
     //    position: absolute;
     //    height: 7px;
     //    width: 7px;
     //    background: #197EDE;
     //    left: -15px;
     //    border-radius: 50%;
     //    content: "";
     //    top: 17px;
     //}
  }
  .notice-item-before {
    position: relative;
    margin: 16px 10px 0px 0px;
    height: 7px;
    width: 7px;
    background: #bacff0;
    border-radius: 50%;
    content: "";
    float: left;
  }
  .other {
    width: 164px;
    color: #F0A42D;
  }
  .notice-date {
    margin-left: 10px;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #9FACB9;
    line-height: 40px;
  }
  }
  .notice-item:hover {
    box-shadow: 0 3px 12px 0 rgba(178, 178, 178, 0.26);
    border-radius: 5px;
    box-sizing: border-box;
    border-bottom: 2px solid #528ffa;
    cursor: pointer;
  .notice-item-before {
    position: relative;
    margin: 16px 10px 0px 0px;
    height: 7px;
    width: 7px;
    background: #528ffa;
    border-radius: 50%;
    content: "";
    float: left;
  }
  .notice-item-title {
    color: #528ffa;
  }
  }
  }
  }
  }
  .moreBtn-div {
    height: 38px;
    margin-top: 70px;
    .moreBtn {
      border: none;
      width: 110px;
      height: 38px;
      background: #186CF5;
      border-radius: 5px;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;
    }
    .moreBtn:hover {
      cursor: pointer;
    }
  }
  ::v-deep .el-carousel__container {
    height: 360px;
  }
  //轮播图指示器设置为圆点
  ::v-deep .el-carousel__indicator--horizontal .el-carousel__button {
    width: 10px;
    height: 10px;
    background: #dee9f6;
    border: 1px solid #dee9f6;
    border-radius: 50%;
    opacity: 0.5;
  }
  ::v-deep .el-carousel__indicator--horizontal.is-active .el-carousel__button{
    width: 10px;
    height: 10px;
    background: #FFB74C;
    border-radius: 50%;
    opacity: 1;
  }
  ::v-deep .el-carousel__indicators--horizontal {
    left: 81% !important;
  }
  @media screen and (max-width: 1366px) {
    .newsListDiv {
      text-align: center;
    }
    .news-div {
      margin: 0px 100px;
      height: 360px;
      display: flex;
      .news-div-left {
        width: 50%;
        margin-top: 35px;
        .carouselNews {
          border-radius: 5px;
          height: 360px;
          width: 590px;
          .top-img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }
          .downShadowDiv {
            height: 48px;
            background: rgba(0,0,0,0.4);
            border-radius: 0px 0px 5px 5px;
            position: relative;
            bottom: 53px;
            text-align: left;
            padding-left: 15px;
            padding-top: 16px;
            .downShadowText {
              width: 377px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              .downSpanClass {
                font-size: 16px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                color: #FFFFFF;
              }
            }
          }
        }
      }
      .news-div-right {
        width: 50%;
        margin-top: 35px;
        .notice-table {
          .notice-item {
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin-bottom: 5px;
            height: 55px;
            .notice-item-title {
              position: relative;
              font-size: 16px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #333333;
              line-height: 40px;
              width: 432px;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              // 为了控制在一行显示，多出字数用省略号代替，所以注释掉
              //&::before {
              //    position: absolute;
              //    height: 7px;
              //    width: 7px;
              //    background: #197EDE;
              //    left: -15px;
              //    border-radius: 50%;
              //    content: "";
              //    top: 17px;
              //}
            }
            .notice-item-before {
              position: relative;
              margin: 16px 10px 0px 0px;
              height: 7px;
              width: 7px;
              background: #bacff0;
              border-radius: 50%;
              content: "";
              float: left;
            }
            .other {
              width: 164px;
              color: #F0A42D;
            }
            .notice-date {
              font-size: 14px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #9FACB9;
              line-height: 40px;
            }
          }
          .notice-item:hover {
            box-shadow: 0 3px 12px 0 rgba(178, 178, 178, 0.26);
            border-radius: 5px;
            box-sizing: border-box;
            border-bottom: 2px solid #528ffa;
            cursor: pointer;
            .notice-item-before {
              position: relative;
              margin: 16px 10px 0px 0px;
              height: 7px;
              width: 7px;
              background: #528ffa;
              border-radius: 50%;
              content: "";
              float: left;
            }
            .notice-item-title {
              color: #528ffa;
            }
          }
        }
      }
    }
    .moreBtn-div {
      height: 38px;
      margin-top: 70px;
      .moreBtn {
        border: none;
        width: 110px;
        height: 38px;
        background: #186CF5;
        border-radius: 5px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
      }
      .moreBtn:hover {
        cursor: pointer;
      }
    }
    ::v-deep .el-carousel__container {
      height: 360px;
    }
    //轮播图指示器设置为圆点
    ::v-deep .el-carousel__indicator--horizontal .el-carousel__button {
      width: 10px;
      height: 10px;
      background: #dee9f6;
      border: 1px solid #dee9f6;
      border-radius: 50%;
      opacity: 0.5;
    }
    ::v-deep .el-carousel__indicator--horizontal.is-active .el-carousel__button{
      width: 10px;
      height: 10px;
      background: #FFB74C;
      border-radius: 50%;
      opacity: 1;
    }
    ::v-deep .el-carousel__indicators--horizontal {
      left: 81% !important;
    }
  }

</style>
