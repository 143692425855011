<!--大厦-->
<template>
  <div class="giEdificeDiv">
    <div class="edifice-div" @click="jumpPage('/giEdifice/index')">
      <div class="edifice-title">
        <span>{{edificeName}}</span>
      </div>
      <div class="edifice-img">
        <el-carousel class="carouselNews" indicator-position="inside" arrow="never">
          <el-carousel-item v-for="(item,index) in imgList" :key="index">
            <img :src="item" alt="" class="top-img">
          </el-carousel-item>
        </el-carousel>
        <div class="QRCodeContainer colSpaceAround">
          <div class="colSpaceAround">
            <img :src="ticketingUrl" alt="" class="qrCodeClass">
            <div class="fontClass">购票约展</div>
          </div>
          <div class="colSpaceAround">
            <img :src="investUrl" alt="" class="qrCodeClass">
            <div class="fontClass">招商入口</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "edifice",
  data() {
    return {
      edificeName: '',
      imgList: [],
      ticketingUrl: '',
      investUrl: '',
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    initData() {
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/basicinfo/detail",
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          let data = res.data.data;
          if (data) {
            this.edificeName = data.edificeName || '';
            if (data.edificePicture) {
              this.imgList = data.edificePicture.split(',')
            }
            this.ticketingUrl = data.edificeTicketing || '';
            this.investUrl = data.edificeAttractInvest || '';
          }
        } else {
          this.$message.error('查询失败')
        }
      }).catch(err => {
        this.$message.error('接口调用异常')
      })
    },
    jumpPage(url) {
      this.$router.push({
        path: url
      });
    },
  }
}
</script>

<style scoped lang="less">
  @import '~@/assets/css/common.less';
  .giEdificeDiv {
    text-align: center;
  }
  .edifice-div {
    margin-top: 50px;
    height: 685px;
    background: url(../../assets/img/edificeBg.png) no-repeat;
    background-size: 100% 685px;
    overflow: hidden; //解决子div设置margin-top使父div也跟着向下移动的问题

    .edifice-title {
      margin-top: 63px;
      font-size: 34px;
      font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
      font-weight: 800;
      color: #FFFFFF;
      line-height: 51px;
      letter-spacing: 1px;
    }

    .edifice-img {
      height: 478px;
      width: 1200px;
      margin: 40px auto;

      .QRCodeContainer {
        width: 130px;
        height: 300px;
        background: rgba(0,0,0, 0.4);
        border-radius: 8px 0px 0px 8px;
        z-index: 30;
        position: relative;
        top: -360px;
        left: 1070px;

        .qrCodeClass {
          width: 110px;
          height: 110px;
          margin: 0 auto 5px;
        }

        .fontClass {
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 21px;
        }
      }
    }
  }
  .carouselNews {
    border-radius: 5px;
    height: 478px;
    width: 1200px;

    .top-img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }

    ::v-deep .el-carousel__container {
      height: 100% !important;
    }
    ::v-deep .el-carousel__button {
       width: 13px;
       height: 13px;
       border-radius: 7px;
    }
    ::v-deep .el-carousel__indicators--horizontal {
      right: 20px!important;
      bottom: 10px;
      left: auto;
      transform: translateX(-30%);
    }

  }
</style>
