<!--在线业务-->
<template>
  <div class="omlineBussinessDiv">
    <div class="business-div">
      <div class="business-title">
        <span>在线业务</span>
      </div>
      <div class="business">
        <div class="business-content">
          <div v-for="(item, index) in businessList" :key="index" class="business-card" @click="businessClick(item)">
            <div>
              <img :src="item.url" alt width="70"/>
            </div>
            <div class="business-card-title">
              {{item.title}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from "@/utils/eventBus.js";
export default {
name: "onlineBusiness",
data() {
  return {
    //在线业务
    businessList: [{
      title: '地标注册',
      url: require('@/assets/img/landmarkReg.png'),
    }, {
      title: '地标续展',
      url: require('@/assets/img/landmarkRenewal.png'),
    }, {
      title: '地标移转',
      url: require('@/assets/img/landmarkTransfer.png'),
    }, {
      title: '资料下载',
      url: require('@/assets/img/dataDownload.png'),
    }, {
      title: '入会申请',
      url: require('@/assets/img/apply.png'),
    }, {
      title: '地标评选',
      url: require('@/assets/img/landmarkSelect.png'),
    }, {
      title: '地标查询',
      url: require('@/assets/img/landmarkQuery.png'),
    }, {
      title: '溯源查询',
      url: require('@/assets/img/sourceQuery.png'),
    }],

  }
},
  mounted() {
    eventBus.$off('toNewPath')
    eventBus.$on('toNewPath',( title)=>{
      this.businessClick()
    })
  },
methods: {
//在线业务点击
businessClick(item) {
  //将点击的名称存储在session中
  if (item != undefined) {
    let title = item.title
    sessionStorage.setItem('title', JSON.stringify(title));
  }
  if (sessionStorage.getItem("HussarToken")) { //已登录
    if (item == undefined) { //先是未登录，登录之后通过eventBus发送已经登录，跳转至对应后台路径
      if (sessionStorage.getItem('title') != '') {
        let onlineTitle = JSON.parse(sessionStorage.getItem('title')) //从session中获取储存的在线业务名称
        if (onlineTitle == "地标注册") {
          window.open(process.env.VUE_APP_Back_PATH + '/onlinebusiness/giregistration/userGiRegistration', '_blank')
        }
        if (onlineTitle == "地标续展") {
          window.open(process.env.VUE_APP_Back_PATH + '/onlinebusiness/landmarkRenewal/giRenewalList', '_blank')
        }
        if (onlineTitle == "地标移转") {
          window.open(process.env.VUE_APP_Back_PATH + '/onlinebusiness/gitransfer/giTransfer', '_blank')
        }
        if (onlineTitle == "资料下载") {
          this.$router.push({
            path: '/onlinebusiness/datadownload/index'
          });
        }
        if (onlineTitle == "入会申请") {
          window.open(process.env.VUE_APP_Back_PATH + '/personalcenter/personalcenter', '_blank')
        }
        if (onlineTitle == "地标评选") {
          window.open(process.env.VUE_APP_Back_PATH, '_blank')
        }
      }
    } else {
      if (item.title == "地标注册") {
        window.open(process.env.VUE_APP_Back_PATH + '/onlinebusiness/giregistration/userGiRegistration', '_blank')
      }
      if (item.title == "地标续展") {
        window.open(process.env.VUE_APP_Back_PATH + '/onlinebusiness/landmarkRenewal/giRenewalList', '_blank')
      }
      if (item.title == "地标移转") {
        window.open(process.env.VUE_APP_Back_PATH + '/onlinebusiness/gitransfer/giTransfer', '_blank')
      }
      if (item.title == "资料下载") {
        this.$router.push({
          path: '/onlinebusiness/datadownload/index'
        });
      }
      if (item.title == "入会申请") {
        window.open(process.env.VUE_APP_Back_PATH + '/personalcenter/personalcenter', '_blank')
      }
      if (item.title == "地标评选") {
        window.open(process.env.VUE_APP_Back_PATH, '_blank')
      }
      if (item.title && item.title == "地标查询") {
        this.$router.push({
          path: '/richPeasant/index'
        });
      }
      if (item.title && item.title == "溯源查询") {
        window.open('https://search.guoli315.com/#/', '_blank')
      }
    }
  } else {//未登录
    if (item.title && item.title == "地标查询") {
      this.$router.push({
        path: '/richPeasant/index'
      });
    } else if (item.title && item.title == "溯源查询") {
      window.open('https://search.guoli315.com/#/', '_blank')
    } else {
      //弹出登录弹窗
      eventBus.$emit('toLogin');
    }
  }
},

}
}
</script>

<style scoped lang="less">
  .omlineBussinessDiv {
    text-align: center;
  }
  .business-div {
    height: 340px;
    background: url(../../assets/img/online.png);
    overflow: hidden; //解决子div设置margin-top使父div也跟着向下移动的问题
  .business-title {
    color: #2E2E2E ;
    font-size: 34px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    margin-top: 76px;
    letter-spacing: 3px;
  }
  .business {
  .business-content {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100px;
  .business-card {
    margin-top: 58px;
    margin-right: 44px;
    margin-left: 44px;
  .business-card-title {
    margin-top: 15px;
    font-size: 18px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 27px;
  }
  }
  .business-card:hover {
    cursor: pointer;
  }
  }
  }
  }

  /*@media screen and (max-width: 1366px) {
    .omlineBussinessDiv {
      text-align: center;
    }
    .business-div {
      height: 340px;
      background: url(../../assets/img/online.png);
      overflow: hidden; //解决子div设置margin-top使父div也跟着向下移动的问题
      .business-title {
        color: #2E2E2E ;
        font-size: 34px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        margin-top: 76px;
        letter-spacing: 3px;
      }
      .business {
        margin: 0px 100px;
        .business-content {
          width: 100%;
          display: flex;
          justify-content: space-between;
          height: 100px;
          .business-card {
            margin-top: 58px;
            .business-card-title {
              margin-top: 15px;
              font-size: 18px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #333333;
              line-height: 27px;
            }
          }
          .business-card:hover {
            cursor: pointer;
          }
        }
      }
    }
  }*/

</style>
