<!--视频动态-->
<template>
  <div class="videoContentDiv">
    <div class="videos-div">
      <div class="videos-title">
        <span>视频动态</span>
      </div>
      <div class="videoClassDiv">
        <div class="videos-content">
          <el-carousel :interval="5000" arrow="always" class="carousel" :autoplay="false">
            <el-carousel-item v-for="(list,index) in newsVideos" :key="index" class="el-car-item">
              <div v-for="(videoList, index1) in list" :key="index1" class="divSrc" @click="videoClick(videoList)">
                <!--左侧视频播放按钮-->
                <!--<div class="videoIconLeft"></div>-->
                <!--右侧视频播放按钮-->
                <!--<div class="videoIconRight"></div>-->
                <!--此写法未实现:poster="videoList.url + '?x-oss-process=video/snapshot,t_1,f_jpg,w_586,h_314,ar_auto'"-->
                <!--设置poster，若设置了封面图则可显示，不设置poster，则视频第一帧作为封面-->
                <!--<video ref="myVideo" :src="videoList.url" id="my-video" width = "550px" height = "314px" :poster="videoList.poster != '' ? videoList.poster : ''" class="videoClass">
                  <source :src="videoList.url" type="video/mp4">
                  Your browser does not support HTML5 video.
                </video>-->
                <!--10.31需求变动，视频太大，加载过慢，后台设置封面图必填，此处只展示封面图，点击时才播放视频，不在此处加载视频了-->
                <img :src="videoList.picFilesUrl" alt="" style="width: 550px; height: 314px" class="videoClass">
                <div class="title">{{videoList.infoName}}</div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div class="dialogDiv">
      <el-dialog
        v-if="viewVideo"
        :visible.sync="viewVideo"
        top="0">
        <div class="videoDiv">
          <video
            :src="this.videoUrl"
            width="50%"
            autoplay="autoplay"
            controls="controls"
            :state="viewVideo"
            ref="vueRef"
          ></video>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "video",
  data() {
    return {
      newsVideos: [],
      viewVideo: false,// 弹窗展示状态
      videoUrl: "", //require('../../video/videoTop.mp4'),
    }
  },
  methods: {
    //点击播放视频
    videoClick(item) {
      this.viewVideo = true
      this.videoUrl = item.url
      this.$refs.vueRef.play();//播放
    },
    getList() {
      //调用视频查询接口
      axios({
        method: 'post',
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/api/portal/video/queryVideo',
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.newsVideos = res.data.data
          for (let i = 0; i < this.newsVideos.length; i++) { //封面图
            this.newsVideos[i].poster = this.newsVideos[i].picFilesUrl
            this.newsVideos[i].url = this.newsVideos[i].videoFilesUrl //process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/play?fileId=" + `${this.newsVideos[i].videoFiles}`
            //没有封面图
            if (this.newsVideos[i].picFilesUrl == null) {
              this.newsVideos[i].poster = ""
            }
          }
          let newDataList = [];
          let current = 0
          if (this.newsVideos && this.newsVideos.length > 0) {
            for (let i = 0; i <= this.newsVideos.length - 1; i++) {
              if (i % 2 !== 0 || i === 0) {	//数据处理成几张展示
                if (!newDataList[current]) {
                  newDataList.push([this.newsVideos[i]])
                } else {
                  newDataList[current].push(this.newsVideos[i])
                }
              } else {
                current++
                newDataList.push([this.newsVideos[i]])
              }
            }
          }
          this.newsVideos = [...newDataList]

        } else {
          this.$message.warning('请求失败')
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped lang="less">
  .videoContentDiv {
    text-align: center;
  }
  .videos-div {
    height: 480px;
  .videos-title {
    font-size: 30px;
    font-weight: bold;
    margin-top: 71px;
    letter-spacing: 3px;
  }
    .videoClassDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      .videos-content {
        width: 79rem;
        margin-top: 56px;
        .carousel{
          width: 100%;
          height: 314px;
          ::v-deep .el-carousel__arrow--left{
            //left: -20px;
            color: #2e74fd;
            background-color: transparent;
            border: 1px solid #2e74fd;
            font-size: 25px;
          }
          ::v-deep .el-carousel__arrow--right {
            //left: 1256px;
            color: #2e74fd;
            background-color: transparent;
            border: 1px solid #2e74fd;
            font-size: 25px;
          }
          .el-car-item {
            width: 100%;
            height: 314px;
            display: flex;
            justify-content:center;
            .divSrc{
              width: 550px;
              height: 314px;
              background: #fff;
              margin-right: 10px;
              margin-left: 10px;
              /*.videoIconLeft {
                position: absolute;
                left: 315px;
                top: 115px;
                display: inline-block;
                width: 60px;
                height: 60px;
                cursor: pointer;
                border-radius: 50%;
                border: 3px solid #fff;
                //box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
                overflow: hidden;
                transition: all 0.3s ease;
                z-index: 998;
              }
              .videoIconLeft:before {
                content: "";
                position: absolute;
                top: 15px;
                left: 22px;
                border-style: solid;
                border-width: 15px 0 15px 25px;
                border-color: transparent transparent transparent #fff;
              }
              .videoIconRight {
                position: absolute;
                right: 315px;
                top: 115px;
                display: inline-block;
                width: 60px;
                height: 60px;
                cursor: pointer;
                border-radius: 50%;
                border: 3px solid #fff;
                //box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
                overflow: hidden;
                transition: all 0.3s ease;
                z-index: 998;
              }
              .videoIconRight:before {
                content: "";
                position: absolute;
                top: 15px;
                left: 22px;
                border-style: solid;
                border-width: 15px 0 15px 25px;
                border-color: transparent transparent transparent #fff;
              }*/
              .videoClass {
                object-fit: fill; //不保证保持原有的比例，内容拉伸填充整个内容容器
                border-radius: 10px;
              }
              .title{
                width: 100%;
                height: 27px;
                line-height: 27px;
                margin: 0 auto;
                text-align: center;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #FFFFFF;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                position: relative;
                bottom: 32px;
                background: rgba(0,0,0,0.4);
                border-radius: 0px 0px 5px 5px;
              }
            }
          }
        }
        .el-car-item {
          width: 100%;
          display: flex;
          justify-content:center;
          .img {
            width: 284px;
            height: 184px;
            margin-right: 20px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .dialogDiv {
    .videoDiv {
      margin-top: 10%;
    }
  }
  ::v-deep .el-dialog {
    background-color: transparent;
    max-width: 100vw;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
  }
  ::v-deep .el-dialog__headerbtn {
    font-size: 60px;
  }
  ::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: #ffffff;
  }
  ::v-deep .el-carousel__indicators {
    display: none;
  }

  /*@media screen and (max-width: 1366px) {
    .videoContentDiv {
      text-align: center;
    }
    .videos-div {
      height: 480px;
      .videos-title {
        font-size: 30px;
        font-weight: bold;
        margin-top: 71px;
        letter-spacing: 3px;
      }
      .videos-content {
        margin: 56px 10px;
        .carousel{
          width: 100%;
          height: 314px;
          padding: 0px 60px;
          ::v-deep .el-carousel__arrow--left{
            left: -55px;
            color: #2e74fd;
            background-color: transparent;
            border: 1px solid #2e74fd;
            font-size: 25px;
          }
          ::v-deep .el-carousel__arrow--right {
            left: 1235px;
            color: #2e74fd;
            background-color: transparent;
            border: 1px solid #2e74fd;
            font-size: 25px;
          }
          .el-car-item {
            width: 100%;
            height: 314px;
            display: flex;
            .divSrc{
              width: 550px;
              height: 314px;
              background: #fff;
              margin-right: 46px;
              !*position: relative;
              top: -44px;*!
              .videoClass {
                object-fit: fill; //不保证保持原有的比例，内容拉伸填充整个内容容器
              }
              .title{
                width: 100%;
                height: 27px;
                line-height: 27px;
                margin: 0 auto;
                text-align: center;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #FFFFFF;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                position: relative;
                bottom: 32px;
                background: rgba(0,0,0,0.4);
                border-radius: 0px 0px 5px 5px;
              }
            }
          }
        }
        .el-car-item {
          width: 100%;
          display: flex;
          .img {
            width: 284px;
            height: 184px;
            margin-right: 20px;
            cursor: pointer;
          }
        }
      }
    }
    .dialogDiv {
      .videoDiv {
        margin-top: 10%;
      }
    }
    ::v-deep .el-dialog {
      background-color: transparent;
      max-width: 100vw;
      width: 100%;
      height: 100vh;
      margin: 0;
      padding: 0;
    }
    ::v-deep .el-dialog__headerbtn {
      font-size: 60px;
    }
    ::v-deep .el-dialog__headerbtn .el-dialog__close {
      color: #ffffff;
    }
    ::v-deep .el-carousel__indicators {
      display: none;
    }
  }*/
</style>
