<!--协会荣誉-->
<template>
  <div class="honorContentDiv">
    <div class="honor-div">
      <div class="honor-title">
        <span>协会荣誉</span>
      </div>
      <div class="honor-content">
        <!--<div v-for="(item, index) in honorList" :key="index" class="honor-card" @click="honorDetail">
          <div class="honor-img">
            <img :src="item.url" alt width="240" height="300"/>
            <div class="certificate-title">
              {{item.title}}
            </div>
          </div>
        </div>-->
        <div v-for="(item, index) in honorList" :key="index" class="honor-content-card">
          <img :src="item.contentImg" alt="" @click="honorDetail">
        </div>
      </div>
      <!--查看更多按钮-->
      <div class="moreBtn-div" @click="honorDetail">
        <button class="moreBtn">查看更多 >></button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "honor",
  data() {
      return {
        //协会荣誉
        honorList: [],
      }
  },
  methods: {
    //协会荣誉查询
    getHonor() {
      let param = {
        id: "744966230582239232", //传值写死
      }
      axios({
        method: "post",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/api/portal/graphicdisplay/queryGraphic',
        data: param
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.honorList = res.data.data
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    //协会荣誉点击进入详情页
    honorDetail() {
      this.$router.push({
        path: '/honor/honorDetail'
      });
    },
  },
  mounted() {
      this.getHonor()
  }
}
</script>

<style scoped lang="less">
  .honorContentDiv {
    text-align: center;
  }
  .honor-div {
    background: url(../../assets/img/honor.png);
    height: 581px;
    overflow: hidden; //解决子div设置margin-top使父div也跟着向下移动的问题v
    .honor-title {
      font-size: 30px;
      font-weight: bold;
      margin: 68px 0 40px;
      letter-spacing: 3px;
    }
  .honor-content {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    height: 284px;

    .honor-content-card {
      margin-left: 10px;
      margin-right: 10px;
      img {
        width: 200px;
        height: 150px;
      }
      img:hover {
        cursor: pointer;
      }
    }
  /*.honor-card {
    transition: all .2s ease;
    width: 214px;
    height: 284px;
    background: #FFFFFF;
    box-shadow: 0 3px 12px 0 rgba(178, 178, 178, 0.26);
    border-radius: 5px;
    box-sizing: border-box;
  .honor-img {
    position: relative;
  .certificate-title {
    position: absolute;
    top: 250px;
  //left: 50px;
    background-color: rgba(126, 126, 126, 0.8);
    width: 214px;
    height: 30px;
    color: #ffffff;
    border-radius: 5px;
    box-sizing: border-box;
  }
  }
  }
    .honor-card:hover {
      cursor: pointer;
    }*/
  }
  }
  .moreBtn-div {
    height: 38px;
    margin-top: 35px;
    .moreBtn {
      border: none;
      width: 110px;
      height: 38px;
      background: #186CF5;
      border-radius: 5px;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;

    }
    .moreBtn:hover {
      cursor: pointer;
    }

  }
  @media screen and (max-width: 1366px) {
    .honorContentDiv {
      text-align: center;
    }
    .honor-div {
      background: url(../../assets/img/honor.png) no-repeat;
      height: 750px;
      overflow: hidden; //解决子div设置margin-top使父div也跟着向下移动的问题v
      .honor-title {
        font-size: 30px;
        font-weight: bold;
        margin: 68px 0 40px;
        letter-spacing: 3px;
      }
      .honor-content {
        display: flex;
        justify-content: center;
        margin: 60px;
        height: 460px;
        .honor-content-card {
          margin: 10px;
          img {
            width: 180px;
            height: 130px;
          }
          img:hover {
            cursor: pointer;
          }
        }
        /*.honor-card {
          transition: all .2s ease;
          width: 214px;
          height: 284px;
          background: #FFFFFF;
          box-shadow: 0 3px 12px 0 rgba(178, 178, 178, 0.26);
          border-radius: 5px;
          box-sizing: border-box;
          .honor-img {
            position: relative;
            .certificate-title {
              position: absolute;
              top: 250px;
              //left: 50px;
              background-color: rgba(126, 126, 126, 0.8);
              width: 214px;
              height: 30px;
              color: #ffffff;
              border-radius: 5px;
              box-sizing: border-box;
            }
          }
        }
        .honor-card:hover {
          cursor: pointer;
        }*/
      }
    }
    .moreBtn-div {
      height: 38px;
      margin-top: 35px;
      .moreBtn {
        border: none;
        width: 110px;
        height: 38px;
        background: #186CF5;
        border-radius: 5px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;

      }
      .moreBtn:hover {
        cursor: pointer;
      }

    }
  }

</style>
